import './Style.css';

function Goodbye() {
  let now = new Date();
  let hours = now.getHours();

  let goodbyeTitle = '';
  if (hours < 18 && hours >= 6) {
    goodbyeTitle = 'Have a good day!';
  } else if (hours < 24 && hours >= 18) {
    goodbyeTitle = 'Have a nice evening!';
  } else {
    goodbyeTitle = 'Have a good day!';
  }
  return (
    <div className="bgAnimate">
      <div className="snow1">
        <div className="finish">
          <div className="finish__box">
            <p>Thanks for your feedback!</p>
            <p>{goodbyeTitle}</p>
            <img className="finish__img" src="/images/shrimp.png" alt="shrimp" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Goodbye;
