import axios from 'axios';
import './Style.css';
import { useNavigate } from 'react-router-dom';

function Review(props) {
  const navigate = useNavigate();
  const inp = props.inputs;
  const handleChange = (e) => {
    props.changeInputs(e);
  };
  const handleSubmit = () => {
    const url = 'https://feedback.pugachova.site/api/shop/save';
    axios
      .post(url, inp).then(console.log(inp, 'inp')
      )
      .catch((error) => alert(error));
  };
  function formSubmit(e) {
    e.preventDefault();
    navigate('/goodbye');
  }

  return (
    <>
      <div className="bgAnimate">
        <div className="snow1">
          <div className="formWrapper">
            <div className="formBox">
              <h1 className="formTitle">
                Let us know what you think of our service
              </h1>
              <div className="form">
                <form className="form__body" action="#" onSubmit={formSubmit}>
                  <div className="form__item">
                    <input
                      className="form__input"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your name"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form__item">
                    <textarea
                      className="form__input"
                      name="comment"
                      placeholder="Your feedback or suggestion"
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <input
                    className={
                      inp.yes === 1
                        ? 'form__button form__buttonYes'
                        : 'form__button form__buttonNo'
                    }
                    type="submit"
                    name="submit"
                    id="submit"
                    value="Send"
                    onClick={handleSubmit}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Review;
