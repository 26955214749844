import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Home from './components/Home';
import Review from './components/Review';

import NotFound from './components/NotFound';
import './App.css';
import { useEffect, useState } from 'react';
import Goodbye from './components/Goodbye';
import Choice from './components/Choice';
import GetShopNum from './components/GetShopNum';

function App() {
  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line
  }, []);
  const id = 1;
  function getQuestions() {
    axios.get(`https://feedback.pugachova.site/apiChoice/choice/${id}`)
      .then(function (response) {
        setQuest1(response.data.quest1);
        setQuest2(response.data.quest2);
        setQuest3(response.data.quest3);
        setQuest4(response.data.quest4);
        setQuest5(response.data.quest5);
        addQuest1(response.data.quest1);
        addQuest2(response.data.quest2);
        addQuest3(response.data.quest3);
        addQuest4(response.data.quest4);
        addQuest5(response.data.quest5);
      });
  }

  const [quest1, setQuest1] = useState('');
  const [quest2, setQuest2] = useState('');
  const [quest3, setQuest3] = useState('');
  const [quest4, setQuest4] = useState('');
  const [quest5, setQuest5] = useState('');

  const [check1, setCheck1] = useState('false');
  const [check2, setCheck2] = useState('false');
  const [check3, setCheck3] = useState('false');
  const [check4, setCheck4] = useState('false');
  const [check5, setCheck5] = useState('false');
  const [shopNum, setShopNum] = useState('');

  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  const [inputs, setInputs] = useState({
    year: year,
    month: month,
    day: day,
    name: '',
    comment: '',
  });

  function changeShopNum(params) {
    setShopNum(params);
  }

  function changeInputs(e) {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  useEffect(() => {
    function setInputCheck() {
      setInputs((values) => ({
        ...values,
        check1: check1,
        check2: check2,
        check3: check3,
        check4: check4,
        check5: check5,
      }));
    }
    setInputCheck();
  }, [check1, check2, check3, check4, check5]);
  const checkboxes = {
    check1: setCheck1,
    check2: setCheck2,
    check3: setCheck3,
    check4: setCheck4,
    check5: setCheck5,
  };
  function changeCheckInputs(e) {
    const checked = e.target.checked;
    for (const key in checkboxes) {
      if (key === e.target.name) {
        checked ? checkboxes[key]('true') : checkboxes[key]('false');
      }
    }
  }

  function changeButtonYes() {
    setInputs((values) => ({ ...values, yes: 1, no: 0 }));
  }
  function changeButtonNo() {
    setInputs((values) => ({ ...values, yes: 0, no: 1 }));
  }

  function addShopNumber(params) {
    const name = 'num';
    const value = params;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  function addTitleCity(params) {
    const name = 'city';
    const value = params;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  function addTitle(params) {
    const name = 'title';
    const value = params;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  function addQuest1(params) {
    const name = 'quest1';
    const value = params;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  function addQuest2(params) {
    const name = 'quest2';
    const value = params;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  function addQuest3(params) {
    const name = 'quest3';
    const value = params;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  function addQuest4(params) {
    const name = 'quest4';
    const value = params;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  function addQuest5(params) {
    const name = 'quest5';
    const value = params;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/:num"
            element={<GetShopNum changeShopNum={changeShopNum} />}
          ></Route>
          <Route
            path="home"
            element={
              <Home
                changeButtonYes={changeButtonYes}
                changeButtonNo={changeButtonNo}
                addShopNumber={addShopNumber}
                shopNum={shopNum}
                addTitleCity={addTitleCity}
                addTitle={addTitle}
              />
            }
          />
          <Route
            path="review"
            element={
              <Review
                inputs={inputs}
                changeInputs={changeInputs}
                check5={check5}
              />
            }
          />
          <Route
            path="choice"
            element={
              <Choice
                changeCheckInputs={changeCheckInputs}
                quest1={quest1}
                quest2={quest2}
                quest3={quest3}
                quest4={quest4}
                quest5={quest5}
              />
            }
          />
          <Route path="goodbye" element={<Goodbye />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
