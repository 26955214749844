import { NavLink } from 'react-router-dom';
import './Style.css';
function Choice(props) {
  
  return (
    <div className="bgAnimate">
      <div className="snow1">
        <div className="formWrapper">
          <div className="formBox">
            <h1 className="formTitle">Choose one or more answers</h1>
            <div className="form">
              <form className="form__body" action="#">
                <div className="form__item">
                  <input
                    className="form__checkbox"
                    type="checkbox"
                    name="check1"
                    id="check1"
                    onChange={(e) => {
                      props.changeCheckInputs(e);
                    }}
                  />
                  <label className="form__checkboxLabel" htmlFor="check1">
                    {props.quest1}
                  </label>
                </div>
                <div className="form__item">
                  <input
                    className="form__checkbox"
                    type="checkbox"
                    name="check2"
                    id="check2"
                    onChange={(e) => {
                      props.changeCheckInputs(e);
                    }}
                  />
                  <label className="form__checkboxLabel" htmlFor="check2">
                    {props.quest2}
                  </label>
                </div>
                <div className="form__item">
                  <input
                    className="form__checkbox"
                    type="checkbox"
                    name="check3"
                    id="check3"
                    onChange={(e) => {
                      props.changeCheckInputs(e);
                    }}
                  />
                  <label className="form__checkboxLabel" htmlFor="check3">
                    {props.quest3}
                  </label>
                </div>
                <div className="form__item">
                  <input
                    className="form__checkbox"
                    type="checkbox"
                    name="check4"
                    id="check4"
                    onChange={(e) => {
                      props.changeCheckInputs(e);
                    }}
                  />
                  <label className="form__checkboxLabel" htmlFor="check4">
                    {props.quest4}
                  </label>
                </div>
                <div className="form__item">
                  <input
                    className="form__checkbox"
                    type="checkbox"
                    name="check5"
                    id="check5"
                    onChange={(e) => {
                      props.changeCheckInputs(e);
                    }}
                  />
                  <label className="form__checkboxLabel" htmlFor="check5">
                    {props.quest5}
                  </label>
                </div>
              </form>
            </div>
            <NavLink className="choice__link form__buttonNo" to="/review">
              Leave a comment
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Choice;
