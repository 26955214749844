import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function GetShopNum(props) {
  const navigate = useNavigate();
  const { num } = useParams();
  useEffect(() => {
    function nuvToHome() {
      props.changeShopNum(num);
      navigate('/home');
    }
    nuvToHome();
  }, [num, navigate, props]);
  //return <h1>Hi!</h1>;
}
export default GetShopNum;
