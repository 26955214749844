import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Style.css';
function Home(props) {
  let now = new Date();
  let hours = now.getHours();
  let homeTitle = '';
  if (hours < 12 && hours >= 6) {
    homeTitle = 'Good morning!';
  } else if (hours < 18 && hours >= 12) {
    homeTitle = 'Good afternoon!';
  } else if (hours < 24 && hours >= 18) {
    homeTitle = 'Good evening!';
  } else {
    homeTitle = 'Good morning!';
  }
  const [titleCity, setTitleCity] = useState('');
  const [title, setTitle] = useState('');
  useEffect(() => {
    getAddress();
  }, []);
  function getAddress() {
    axios
      .get(`https://feedback.pugachova.site/api/address/${props.shopNum}`)
      .then(function (response) {
        setTitleCity(response.data.city);
        setTitle(response.data.address);
        props.addTitleCity(response.data.city);
        props.addTitle(response.data.address);
        props.addShopNumber(response.data.num);
      });
  }

  return (
    <div className="home">
      <div className="bgAnimate">
        <div className="snow1"></div>

        <div className="home__box">
          <div className="home__header">
            <img
              className="home__titleImg "
              src="./images/logo.svg"
              alt="logo"
            ></img>
            <div className="home__headerTitle">
              <h2 className="home__address">{title}</h2>
              <h1 className="home__address">{titleCity}</h1>
            </div>
          </div>
          <div className="home__wrapper">
            <p className="home__title">{homeTitle}</p>
            <p className="home__text">
              Are you satisfied with the quality of service?
            </p>
            <div className="home__items-box">
              <div className="home__items">
                <div className="home__item">
                  <NavLink
                    className="home__link"
                    to="/review"
                    onClick={() => {
                      props.changeButtonYes();
                      getAddress();
                    }}
                  >
                    <img
                      className="home__img"
                      src="./images/yes.png"
                      alt="img"
                    ></img>
                    <p className="home__button">Yes</p>
                  </NavLink>
                </div>
                <div className="home__item">
                  <NavLink
                    className="home__link"
                    to="/choice"
                    onClick={() => {
                      props.changeButtonNo(1);
                      getAddress();
                    }}
                  >
                    <img
                      className="home__img"
                      src="./images/no.png"
                      alt="img"
                    ></img>
                    <p className="home__button">No</p>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
